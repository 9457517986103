@import url("libs/font/roboto/roboto.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 140%;
}

.container {
  margin-top: 7rem;
}

.bg-gray-100 {
  background-color: #f4f4f5;
}

.gray-600 {
  color: #777c83;
}

.gray-800 {
  color: #495059;
}

h2, .h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  line-height: 110%;
  font-weight: bold;
  letter-spacing: -0.01em;
}

h3, .h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  line-height: 120%;
  font-weight: bold;
  letter-spacing: -0.01em;
}

h6, .h6 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 140%;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 16px;
  margin-bottom: 1rem;
}

.danger {
  color: #d34236;
}

.body-lg {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 130%;
}

.body-sm {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 150%;
}

.h6 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 140%;
}

.subtitle-lg {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 130%;
}

.p-6 {
  padding: 23px !important;
}

.pl-4, .px-4 {
  padding-left: 16px !important;
}

.pr-4, .px-4 {
  padding-right: 16px !important;
}

.pb-4, .py-4 {
  padding-bottom: 16px !important;
}

.pt-4, .py-4 {
  padding-top: 16px !important;
}

.pl-6, .px-6 {
  padding-left: 24px !important;
}

.pr-6, .px-6 {
  padding-right: 24px !important;
}

.pb-16, .py-16 {
  padding-bottom: 64px !important;
}

.pl-10, .px-10 {
  padding-left: 40px !important;
}

.pr-10, .px-10 {
  padding-right: 40px !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pl-3, .px-3 {
  padding-left: 12px !important;
}

.pr-3, .px-3 {
  padding-right: 12px !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 12px !important;
}

.pt-3, .py-3 {
  padding-top: 12px !important;
}


.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.mt-2, .my-2 {
  margin-top: 8px !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mt-3, .my-3 {
  margin-top: 12px !important;
}

.mt-4, .my-4 {
  margin-top: 16px !important;
}

.mr-4, .mx-4 {
  margin-right: 16px !important;
}

.ml-6, .mx-6 {
  margin-left: 24px !important;
}

.mb-6, .my-6 {
  margin-bottom: 24px !important;
}

.ml-7, .mx-7 {
  margin-left: 28px !important;
}

.border-bottom {
  border-bottom: 1px solid #e8e9ea !important;
}

.center-form {
  max-width: 27rem;
  margin-top: 2rem;
}

.w-100 {
  width: 100% !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3, .my-3 {
  margin-bottom: 12px !important;
}

.mb-4, .my-4 {
  margin-bottom: 16px !important;
}

.mb-6, .my-6 {
  margin-bottom: 24px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-8, .my-8 {
  margin-top: 32px !important;
}

.ml-16, .mx-16 {
  margin-left: 64px !important;
}

.mr-16, .mx-16 {
  margin-right: 64px !important;
}

.pl-16, .px-16 {
  padding-left: 64px !important;
}

.pr-16, .px-16 {
  padding-right: 64px !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}


.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-success {
  color: #1aa84c !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.normal-icon {
  font-size: 2rem !important;
}

.form-group {
  margin-bottom: 28px;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

input[type="radio"], input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.navigation {
  background-color: #1c2430;
  color: #fff;
}


.logo {
  grid-area: logo;
}

.content-600w {
  width: 100%;
  max-width: 600px;
}

@media (max-width: 767.98px) {
  .container {
    margin-top: 9rem;
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .logo-grid {
    display: grid;
    grid-template: "logo menu-btn" auto/auto 5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 4.125rem;
  }

  .page-container {
    padding-top: 4.125rem;
  }

  .navigation .logo img {
    height: 1.25rem;
  }

  .navigation-top-content {
    padding: 18px !important;
    flex-direction: column-reverse;
    text-align: center;
  }
  .navigation-top-content div:last-child {
    margin-left: initial !important;
  }
}

@media (min-width: 576px) {
  .center-form {
    margin-top: 5.5rem;
  }

  .page-container .navigation-content {
    position: fixed;
    width: 15.625rem;
    height: 100vh;
  }

  .page-container {
    display: grid;
    grid-template: "nav container" auto/15.625rem auto;
  }

  .logo img {
    height: 2.375rem;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 12px !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 12px !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 20px !important;
  }

  .mt-sm-8, .my-sm-8 {
    margin-top: 32px !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .footer-form {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: "children actions";
    -webkit-box-align: center;
    align-items: center;
    column-gap: 1rem;
  }

  .footer-action {
    flex-direction: row;
    grid-area: actions / actions / actions / actions;
    justify-self: end;
  }

  .footer-action > :first-child {
    margin-right: calc(8px);
  }

  .footer-action > :last-child {
    margin-bottom: inherit;
  }
}


.page-container {
  height: 100vh;
}

.page-container .navigation-content {
  grid-area: nav;
  overflow-y: scroll;
  z-index: 999;
}

.page-container .page-content {
  grid-area: container;
}

.page-container .content {
  min-height: calc(100vh - 4.125rem);
}

.footer-action {
  display: flex;
  flex-direction: column-reverse;
}

.footer-action > :last-child {
  margin-bottom: calc(8px);
}

.pointer {
  color: inherit;
  cursor: pointer;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.invisible {
  visibility: hidden !important;
}

.white {
  color: white;
}

.bg-white {
  background-color: #fff;
}

.navigation-top-fixed {
  min-height: 7rem;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}

.navigation-top-content {
  max-width: 85rem;
  display: flex;
  padding: 32px 64px;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navigation-top-content div:last-child {
  margin-left: auto;
}

.navigation-content a {
  text-decoration: none;
  background-color: transparent;
}

.navigation-content img {
  vertical-align: middle;
  border-style: none;
}

.rounded {
  border-radius: .5rem !important;
}

.truncate {
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

a {
  color: #1886f7;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0e3062;
  text-decoration: underline;
}

.text-info {
  color: #1886f7 !important;
}

#process-form .loader:before {
  left: -15px;
}

#process-form .loader:after {
  left: 15px;
}

#process-form #btn-next {
  min-width: 130px;
}

.footer-inline {
  display: inline-block;
  align-content: end;
}

.btn-inline {
  width: -moz-fit-content !important;
  width: fit-content !important;
  float: right;
  margin-right: 20px;
}

.btn-left {
  float: left;
}
.btn-inline:first-child {
  margin-right: 0;
}

.btn-cancel {
  color: black !important;
}

.btn-delete {
  color: #d34236 !important;
}

.btn-submit {
  background-color: rgb(122, 122, 255);
  border-color: transparent;
}

.btn-submit :hover {
  background-color: rgb(150, 150, 250);
  border-color: rgb(150, 150, 250);
  color: rgb(28, 36, 48);
}

.btn-submit :focus {
  outline: 0;
  background-color: rgb(150, 150, 250);
  box-shadow: rgb(122, 122, 255) 0 0 0 2px;
}

@media (max-width: 767.98px) {
  .footer-inline {
    display: block;
  }

  .footer-inline .btn-inline {
    margin: 0;
    width: 100% !important;
  }
}

.modal {
  position: fixed;
  inset: 0px;
  z-index: 2000;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.3s ease 0s;
  opacity: 1;
  visibility: visible;
}

.overlay-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(28, 36, 48, 0.5);
}

.modal-content {
  position: relative;
  max-width: 90%;
  height: auto;
  max-height: 80vh;
  border-radius: 0.5rem;
  background: rgb(255, 255, 255);
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.modal-head-control {
  position: relative;
  min-height: 4rem;
  border-bottom: none;
}

.cast-iron {
  color: #1c2430;
}

.modal-close {
  appearance: none;
  border: 0px;
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.modal-body {
  height: auto;
  max-width: 768px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-footer {
  height: auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: calc(32px);
  padding-right: calc(32px);
  padding-bottom: calc(56px);
}

.fuyRQH {
  margin-bottom: calc(24px);
}

.bCwWdn {
  margin-bottom: calc(28px);
}

.paragraph-scroll {
  overflow: auto;
  padding: 10px;
  height: 400px;
  border: 1px solid;
  border-radius: 10px;
}

.text-underline {
  text-decoration: underline;
}

.modal-actions {
  display: flex;
  width: 300px;
  justify-content: space-evenly;
}